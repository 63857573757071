// Show loading inside submit button and set readonly to all submitted form input

let form = document.querySelectorAll('form')
form.forEach((el) => {
    el.addEventListener('submit', (ev) => {
        let input = el.querySelectorAll('input')
        input.forEach((elm) => {
            elm.setAttribute('readonly','readonly')
            elm.classList.add('bg-gray-200')
        })

        let submit = el.querySelector('[type=submit]')
        submit.style.width = submit.offsetWidth +'px'
        if(! (el.getAttribute('name','confirm-item') || el.getAttribute('name','delete-item'))) {
            submit.classList.add('p-1')
        }
        submit.setAttribute('submit-label', submit.innerHTML?.trim())
        submit.innerHTML = `
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="w-9 h-9 mx-auto bg-none block" style="margin: auto; background: none; display: block; shape-rendering: auto;" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <g>
                    <path d="M50 17A33 33 0 1 0 83 50.00000000000001" fill="none" stroke="#ffffff" stroke-width="14"></path>
                    <path d="M49 17L49 17L49 17L49 17" fill="#ffffff"></path>
                    <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                </g>
            </svg>
        `
    })
})
