import 'select2'
import 'select2/dist/css/select2.css'
import html5tooltips from 'html5tooltipsjs'
import 'html5tooltipsjs/html5tooltips.css'
import 'jquery.marquee'
import 'animate.css'
import { CountUp } from 'countup.js'

/**
 * Custom search placeholder option
 * Dependency: select2
 */
(function($) {
    var SearchDropdown = $.fn.select2.amd.require('select2/dropdown/search')
    var _renderSearchDropdown = SearchDropdown.prototype.render
    SearchDropdown.prototype.render = function(decorated) {
        // invoke parent method
        var $rendered = _renderSearchDropdown.apply(this, Array.prototype.slice.apply(arguments))
        this.$search.attr('placeholder', this.options.get('searchInputPlaceholder'))
        return $rendered
    }
})(window.jQuery)

/**
 * Place any jQuery/helper plugins in here.
 */
$(function () {
    /**
     * Checkbox tree for permission selecting
     */
    let permissionTree = $('.permission-tree :checkbox');

    permissionTree.on('click change', function (){
        if($(this).is(':checked')) {
            $(this).siblings('ul').find('input[type="checkbox"]').attr('checked', true).attr('disabled', true);
        } else {
            $(this).siblings('ul').find('input[type="checkbox"]').removeAttr('checked').removeAttr('disabled');
        }
    });

    permissionTree.each(function () {
        if($(this).is(':checked')) {
            $(this).siblings('ul').find('input[type="checkbox"]').attr('checked', true).attr('disabled', true);
        }
    });

    /**
     * Disable submit inputs in the given form
     *
     * @param form
     */
    function disableSubmitButtons(form) {
        form.find('input[type="submit"]').attr('disabled', true);
        form.find('button[type="submit"]').attr('disabled', true);
    }

    /**
     * Enable the submit inputs in a given form
     *
     * @param form
     */
    function enableSubmitButtons(form, label) {
        if(label) {
            form.find('[type="submit"]').html(label)
        }
        form.find('input[type="submit"]').removeAttr('disabled');
        form.find('button[type="submit"]').removeAttr('disabled');
    }

    /**
     * Disable all submit buttons once clicked
     */
    $('form').submit(function () {
        disableSubmitButtons($(this));
        return true;
    });

    /**
     * Add a confirmation to a delete button/form
     */
    $('body').on('submit', 'form[name=delete-item]', function(e) {
        e.preventDefault();
        let label = e.currentTarget.querySelector('[type=submit]').getAttribute('submit-label')

        Swal.fire({
            title: 'Are you sure you want to delete this item?',
            showCancelButton: true,
            confirmButtonText: 'Confirm Delete',
            cancelButtonText: 'Cancel',
            icon: 'warning'
        }).then((result) => {
            if (result.value) {
                this.submit()
            } else {
                enableSubmitButtons($(this), label);
            }
        });
    })
        .on('submit', 'form[name=confirm-item]', function (e) {
            e.preventDefault();
            let label = e.currentTarget.querySelector('[type=submit]').getAttribute('submit-label')

            Swal.fire({
                title: 'Are you sure you want to do this?',
                showCancelButton: true,
                confirmButtonText: 'Continue',
                cancelButtonText: 'Cancel',
                icon: 'warning'
            }).then((result) => {
                if (result.value) {
                    this.submit()
                } else {
                    enableSubmitButtons($(this), label);
                }
            });
        })
        .on('click', 'a[name=confirm-item]', function (e) {
        /**
         * Add an 'are you sure' pop-up to any button/link
         */
        e.preventDefault();

        Swal.fire({
            title: 'Are you sure you want to do this?',
            showCancelButton: true,
            confirmButtonText: 'Continue',
            cancelButtonText: 'Cancel',
            icon: 'info',
        }).then((result) => {
            result.value && window.location.assign($(this).attr('href'));
        });
    });

    // Remember tab on page load
    $('a[data-toggle="tab"], a[data-toggle="pill"]').on('shown.bs.tab', function (e) {
        let hash = $(e.target).attr('href');
        history.pushState ? history.pushState(null, null, hash) : location.hash = hash;
    });

    let hash = window.location.hash;
    if (hash) {
        $('.nav-link[href="'+hash+'"]').tab('show');
    }

    window.initSelect = () => {
        document.querySelectorAll('select.select2:not([data-ajax--url])').forEach(elm => {
            if(!elm.hasInitSelect) {
                elm.hasInitSelect = true

                $(elm).select2({
                    dropdownCssClass: elm.hasAttribute('data-dropdown-css-class') || undefined,
                    language: {
                        errorLoading: () => {
                            return elm.getAttribute('select2-language--error-loading') || 'The results could not be loaded.'
                        },
                        inputTooLong: function (e) {
                            var t = e.input.length - e.maximum, n = "Please delete " + t + " character";
                            return t != 1 && (n += "s"), n
                        },
                        inputTooShort: function (e) {
                            var t = e.minimum - e.input.length, n = "Please enter " + t + " or more characters";
                            return n
                        },
                        loadingMore: () => {
                            return elm.getAttribute('select2-language--loading-more') || 'Loading more results…'
                        },
                        maximumSelected: function (e) {
                            var t = "You can only select " + e.maximum + " item";
                            return e.maximum != 1 && (t += "s"), t
                        },
                        noResults: () => {
                            return elm.getAttribute('select2-language--no-results') || 'No results found'
                        },
                        searching: () => {
                            return elm.getAttribute('select2-language--searching') || 'Searching...'
                        },
                        removeAllItems: () => {
                            return elm.getAttribute('select2-language--remove-all-items') || 'Remove all items'
                        }
                    },
                    searchInputPlaceholder: elm.getAttribute('data-search-input-placeholder') || elm.getAttribute('data-placeholder') || elm.getAttribute('placeholder') || 'Search...',
                    minimumResultsForSearch: elm.getAttribute('data-minimum-results-for-search') || 'Infinity',
                    allowClear: elm.hasAttribute('data-allow-clear') || false,
                    placeholder: elm.getAttribute('data-placeholder') || elm.getAttribute('placeholder') || false,
                    templateResult: (option) => {
                        if(option.sub) {
                            return $('<div class="my-1"><div class="leading-none font-medium">' + option.text + '</div><small class="block font-light italic">' + option.sub + '</small></div>');
                        }

                        return option.text;
                    },
                })
            }
        })
    }
    window.initSelect()

    window.ajaxSelect = () => {
        document.querySelectorAll('select.select2[data-ajax--url]').forEach(elm => {
            if(!elm.hasAjaxSelect) {
                elm.hasAjaxSelect = true

                $(elm).select2({
                    dropdownCssClass: elm.hasAttribute('data-dropdown-css-class') || undefined,
                    language: {
                        errorLoading: () => {
                            return elm.getAttribute('select2-language--error-loading') || 'The results could not be loaded.'
                        },
                        inputTooLong: function (e) {
                            var t = e.input.length - e.maximum, n = "Please delete " + t + " character";
                            return t != 1 && (n += "s"), n
                        },
                        inputTooShort: function (e) {
                            var t = e.minimum - e.input.length, n = "Please enter " + t + " or more characters";
                            return n
                        },
                        loadingMore: () => {
                            return elm.getAttribute('select2-language--loading-more') || 'Loading more results…'
                        },
                        maximumSelected: function (e) {
                            var t = "You can only select " + e.maximum + " item";
                            return e.maximum != 1 && (t += "s"), t
                        },
                        noResults: () => {
                            return elm.getAttribute('select2-language--no-results') || 'No results found'
                        },
                        searching: () => {
                            return elm.getAttribute('select2-language--searching') || 'Searching...'
                        },
                        removeAllItems: () => {
                            return elm.getAttribute('select2-language--remove-all-items') || 'Remove all items'
                        }
                    },
                    placeholder: elm.getAttribute('data-placeholder') || elm.getAttribute('placeholder') || '',
                    searchInputPlaceholder: elm.getAttribute('data-search-input-placeholder') || elm.getAttribute('data-placeholder') || elm.getAttribute('placeholder') || 'Search...',
                    minimumInputLength: elm.getAttribute('data-minimum-input-length') || 0,
                    templateResult: (option) => {
                        if(option.sub) {
                            return $('<div class="my-1"><div class="leading-none font-medium">' + option.text + '</div><small class="block font-light italic">' + option.sub + '</small></div>');
                        }

                        return option.text;
                    },
                    ajax: {
                        url: elm.getAttribute('data-ajax--url') || '',
                        method: elm.getAttribute('data-ajax--method') || 'POST',
                        dataType: elm.getAttribute('data-ajax--data-type') || 'json',
                        delay: elm.getAttribute('data-ajax--delay') || 500,
                        contentType: elm.getAttribute('data-ajax--content-type') || "application/json; charset=utf-8",
                        headers: {
                            'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                        },
                        data: (terms) => {
                            let params = {
                                term: terms.term,
                                page: terms.page || 1,
                            }

                            return JSON.stringify(params)
                        },
                        processResults: (data, params) => {
                            return {
                                results: data.data,
                                pagination: {
                                    more: data.current_page != data.last_page
                                }
                            }
                        },
                    }
                })
            }
        })
    }
    ajaxSelect()

    /**
     * Auto-hide and resize navbar when scrolling
     */
    const nav = document.querySelector('.nav:not(.landing)')
    if(nav) {
        var prevScrollpos = window.pageYOffset;
        window.addEventListener('scroll', fixNav)
    }

    function fixNav() {
        var currentScrollPos = window.pageYOffset;

        if (prevScrollpos > currentScrollPos) {
            nav.classList.add('top-0', 'transition-all')
            nav.classList.remove('-top-[100px]')
        } else {
            nav.classList.remove('top-0')
            nav.classList.add('-top-[100px]', 'transition-all')
        }

        prevScrollpos = currentScrollPos;

        if (window.scrollY > nav.offsetHeight + 72) {
            nav.classList.add('active','group','shadow-lg', 'transition-all')
        } else {
            nav.classList.remove('active','group','shadow-lg')
        }
    }

    /**
     * Overlay menu
     */
    document.querySelectorAll('#open-overlay-menu').forEach(el => {
        el.addEventListener('click', (ev) => {
            document.querySelectorAll('#overlay-menu').forEach(elm => {
                elm.classList.remove('h-0', 'opacity-0', 'group', 'menu-hide');
                elm.classList.add('h-screen', 'bg-opacity-95');
            })
        })
    })
    document.querySelectorAll('#close-overlay-menu').forEach(el => {
        el.addEventListener('click', (ev) => {
            document.querySelectorAll('#overlay-menu').forEach(elm => {
                elm.classList.remove('h-screen', 'bg-opacity-95');
                elm.classList.add('h-0', 'opacity-0', 'group', 'menu-hide');
            })
        })
    })

    /**
     * Initialize marquee
     */
    $('.marquee').marquee({
        duration:  $('.marquee').width()*20,
        pauseOnHover: true,
        duplicated: true
    })

    /**
     * Count up numbers
     */
    document.querySelectorAll('.count').forEach(elm => {
        let numAnim = new CountUp(elm.id, elm.innerHTML, {
            duration: 1,
            decimalPlaces: 2,
        });
        numAnim.start()
    })

    /**
     * Show/hide password input
     * Dependency: tailwind, FontAwesome
     *
     * <input type="password" />
     */
    // const enableTogglePassword = () => {
    window.enableTogglePassword = () => {
        document.querySelectorAll('[type="password"]').forEach((el) => {
            if(!el.hasEnableTogglePassword) {
                el.hasEnableTogglePassword = true
                el.idTogglePassword = Math.random().toString(36).substring(2,10)
                el.onfocus = showTogglePassword
                el.oninput = showTogglePassword
                el.onblur = hideTogglePassword
            }
        })
    }
    // enableTogglePassword()
    (new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
            window.enableTogglePassword()
        }
    })).observe(document.body, { attributes: true, subtree: true, childList: true, characterData: true });
    // setTimeout(() => document.body.addEventListener('DOMNodeInserted', () => enableTogglePassword()), 1000)

    function showTogglePassword(ev) {
        let idTogglePassword = ev.target.idTogglePassword
        let input = ev.srcElement
        let parent = input.closest('div')
        let toggle = parent.querySelector(`#toggle_password_${idTogglePassword}`)
        let eye = `
        <button type="button" class="h-full absolute right-2 top-1/2 transform -translate-y-1/2" id="toggle_password_${idTogglePassword}">
        <svg class="cursor-pointer w-6 h-6 eye-open" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
        <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
        <svg class="cursor-pointer w-6 h-6 eye-close hidden" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
        </svg>
        </button>
        `

        if(!toggle && input.value != '') {
            parent.classList.add('relative') // tailwind
            input.classList.add('pr-11') // tailwind
            input.insertAdjacentHTML('afterend',eye)
            toggle = parent.querySelector(`#toggle_password_${idTogglePassword}`)
            toggle.onclick = triggerTogglePassword
            toggle.onmouseenter = hoverTogglePassword
            toggle.onmouseleave = stopHoverTogglePassword
        }
    }
    function triggerTogglePassword(ev) {
        let toggle = ev.target
        let input = toggle.closest('button').previousElementSibling

        if(toggle.classList.contains('eye-close')) {
            input.type = 'password'
            toggle.classList.add('hidden')
            toggle.previousElementSibling.classList.remove('hidden')
        }
        if(toggle.classList.contains('eye-open')) {
            input.type = 'text'
            toggle.classList.add('hidden')
            toggle.nextElementSibling.classList.remove('hidden')
        }
    }
    function hoverTogglePassword(ev) {
        ev.srcElement.stopTogglePassword = true
    }
    function stopHoverTogglePassword(ev) {
        ev.srcElement.stopTogglePassword = false
    }
    function hideTogglePassword(ev) {
        let idTogglePassword = ev.target.idTogglePassword
        let input = ev.srcElement
        let parent = input.closest('div')
        let toggle = parent.querySelector(`#toggle_password_${idTogglePassword}`)

        setTimeout(() => {
            if(toggle && !toggle.stopTogglePassword) {
                input.type = 'password'
                if(input.value == '') {
                    input.nextElementSibling.remove()
                }
            }
        }, 10)
    }

    /**
     * Accordion button listener
     * Dependency: FontAwesome (optional)
     * Source: https://tailwindcomponents.com/component/accordion-with-tailwindcss
     *
     * <div class="accordion-header"><!-- Header --><i class="fa-solid fa-plus"></i></div>
     */

    // document.body.addEventListener('DOMNodeInserted', () => {
    //     const accordionHeader = document.querySelectorAll('.accordion-header')
    //     accordionHeader.forEach((el) => {
    //         if(!el.hasAccordionHeader) {
    //             el.hasAccordionHeader = true
    //             el.addEventListener('click', (ev) => toggleAccordion(el, ev))
    //         }
    //     })
    // })
    (new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
            const accordionHeader = document.querySelectorAll('.accordion-header')
            accordionHeader.forEach((el) => {
                if(!el.hasAccordionHeader) {
                    el.hasAccordionHeader = true
                    el.addEventListener('click', (ev) => toggleAccordion(el, ev))
                }
            })
        }
    })).observe(document.body, { attributes: true, subtree: true, childList: true, characterData: true });

    function toggleAccordion(el, ev) {
        const header = el
        let accordionContent = header.nextElementSibling



        if(accordionContent) {
            if(!accordionContent.classList.contains('accordion-content')) {
                accordionContent = header.previousElementSibling
            }

            let previousAccordionContent = accordionContent.previousElementSibling
            if(!previousAccordionContent.classList.contains('accordion-content')) {
                previousAccordionContent = null
            }

            let accordionMaxHeight = accordionContent.style.maxHeight

            if (accordionMaxHeight == "0px" || accordionMaxHeight.length == 0) {
                if(previousAccordionContent) {
                    previousAccordionContent.style.maxHeight = `${previousAccordionContent.scrollHeight}px`
                    setTimeout(() => {
                        previousAccordionContent.style.maxHeight = `0px`
                    }, 10)
                }

                accordionContent.style.maxHeight = `${accordionContent.scrollHeight}px`
                setTimeout(() => {
                    accordionContent.style.maxHeight = `max-content`
                }, 500)

                if(header.querySelector("svg")) {
                    header.querySelector("svg").classList.add("rotate-[180deg]")
                }
            } else {
                if(previousAccordionContent) {
                    previousAccordionContent.style.maxHeight = `${previousAccordionContent.scrollHeight}px`
                    setTimeout(() => {
                        previousAccordionContent.style.maxHeight = `max-content`
                    }, 500)
                }

                accordionContent.style.maxHeight = `${accordionContent.scrollHeight}px`
                setTimeout(() => {
                    accordionContent.style.maxHeight = `0px`
                }, 10)

                if(header.querySelector("svg")) {
                    header.querySelector("svg").classList.remove("rotate-[180deg]")
                }
            }

            let block = header.querySelector(".block")
            let hidden = header.querySelector(".hidden")
            if(block) {
                block.classList.remove('block')
                block.classList.add('hidden')
            }
            if(hidden) {
                hidden.classList.remove('hidden')
                hidden.classList.add('block')
            }
        }
    }

    /**
     * Animated tabbed section listener
     * Dependency: Tailwind (optional)
     *
     * <div class="tabs-3">
     *   <button data-content-active="#id1" class="active tabs-item"><!-- Title 1 --></button>
     *   <button data-content-active="#id2" class="tabs-item"><!-- Title 2 --></button>
     *   <span class=" tab-item-animate-3"></span>
     * </div>
     * <div class="content-container" data-container-active="#id1">
     *   <div id="id1"><!-- Content 1 --></div>
     *   <div id="id2"><!-- Content 1 --></div>
     * </div>
     */

    document.querySelectorAll('.tabs-item').forEach(elm => {
        document.querySelector('.content-container').style.height = (document.querySelector('.content-container > div:first-child > div.relative').offsetHeight + 54) +'px';

        elm.addEventListener('click', ev => {

            document.querySelectorAll('.tabs-item').forEach(el => {
                el.classList.remove('active','text-white')
                el.classList.add('text-gray-500')
            })

            elm.classList.add('active','text-white')
            elm.classList.remove('text-gray-500')

            let container = document.querySelector('.content-container')
            let nextActive = elm.getAttribute('data-content-active')
            let nextActiveElement = document.querySelector(nextActive)
            let currentActive = container.getAttribute('data-container-active')
            let currentActiveElement = document.querySelector(currentActive)

            container.style.height = document.querySelector(nextActive).offsetHeight +'px';
            nextActiveElement.classList.remove('invisible')

            if(currentActiveElement.classList.contains('animate__fadeInLeft')) {
                currentActiveElement.classList.remove('animate__fadeInRight','animate__fadeInLeft')

                if(nextActiveElement.classList.contains('animate__fadeOutRight')) {
                    currentActiveElement.classList.add('animate__fadeOutLeft')

                    setTimeout(() => {
                        nextActiveElement.classList.remove('animate__fadeOutRight','animate__fadeOutLeft')
                        nextActiveElement.classList.add('animate__fadeInRight')
                    }, 100)
                }

                if(nextActiveElement.classList.contains('animate__fadeOutLeft')) {
                    currentActiveElement.classList.add('animate__fadeOutRight')

                    setTimeout(() => {
                        nextActiveElement.classList.remove('animate__fadeOutRight','animate__fadeOutLeft')
                        nextActiveElement.classList.add('animate__fadeInLeft')
                    }, 100)
                }
            }

            if(currentActiveElement.classList.contains('animate__fadeInRight')) {
                currentActiveElement.classList.remove('animate__fadeInRight','animate__fadeInLeft')

                if(nextActiveElement.classList.contains('animate__fadeOutLeft')) {
                    currentActiveElement.classList.add('animate__fadeOutRight')

                    setTimeout(() => {
                        nextActiveElement.classList.remove('animate__fadeOutRight','animate__fadeOutLeft')
                        nextActiveElement.classList.add('animate__fadeInLeft')
                    }, 100)
                }

                if(nextActiveElement.classList.contains('animate__fadeOutRight')) {
                    currentActiveElement.classList.add('animate__fadeOutLeft')

                    setTimeout(() => {
                        nextActiveElement.classList.remove('animate__fadeOutRight','animate__fadeOutLeft')
                        nextActiveElement.classList.add('animate__fadeInRight')
                    }, 100)
                }
            }

            container.setAttribute('data-container-active', nextActive)

            setTimeout(() => {
                window.scrollTo(0, document.body.scrollHeight);
            }, 150)
        })
    })
})
