window.copy_hover = () => {
    document.querySelectorAll('.hover-copy').forEach(elm => {
        elm.onmouseenter = (ev) => showCopy(elm, elm.getAttribute('data-copy-id'))
        elm.onclick = (ev) => clickCopy('#copy_'+elm.getAttribute('data-copy-id'), elm.getAttribute('data-copy-text'), elm.getAttribute('data-copy-raw'))
        elm.onmouseleave = (ev) => hideCopy(elm)
    })
}
// window.copy_hover()
(new MutationObserver((mutationsList) => {
    for (const mutation of mutationsList) {
        window.copy_hover()
    }
})).observe(document.body, { attributes: true, subtree: true, childList: true, characterData: true });

window.copy_click = () => {
    document.querySelectorAll('.click-copy').forEach(elm => {
        elm.onclick = (ev) => clickCopy('#copy_'+elm.getAttribute('data-copy-id'), elm.getAttribute('data-copy-text'), elm.getAttribute('data-copy-raw'))
    })
}
// window.copy_click()
(new MutationObserver((mutationsList) => {
    for (const mutation of mutationsList) {
        window.copy_click()
    }
})).observe(document.body, { attributes: true, subtree: true, childList: true, characterData: true });

function showCopy(elm,id) {
    elm.closest('div').classList.add('relative','scale-105','transform','transition-all')
    elm.insertAdjacentHTML('afterend',`<small class="absolute text-[11px] left-1/2 top-full transform -translate-x-1/2 translate-y-1/2 z-[9999999999] bg-gray-200 border-gray-300 border rounded-lg px-2 py-0.5 font-bold leading-none text-gray-400 cursor-pointer" id="copy_`+id+`">`+elm.getAttribute('data-lang-copy')+`</small>`)
}
function hideCopy(elm) {
    elm.closest('div').classList.remove('relative','scale-105','transform','transition-all')
    elm.nextElementSibling.remove()
}

function clickCopy(selector,variable,raw = false) {
    var copyText = variable
    var originalText = document.querySelector(selector).textContent
    let copied = document.querySelector(selector).previousElementSibling.getAttribute('data-lang-copied')
    document.querySelector(selector).textContent = copied
    setTimeout(() => {
        var _selector = document.querySelector(selector)
        if(_selector) {
            _selector.textContent = originalText;
        }
    }, 1000);
    if(!raw) {
        copyText = copyText.replace(/<[^>]+>/g, '')
    }
    navigator.clipboard.writeText(copyText);
}
